import { Link } from 'gatsby';
import propTypes from 'prop-types';
import React from 'react';

import styles from './articlecard.module.css';

const ArticleCard = ({ article }) => {
  const { name, slug, metaDescription } = article;
  return (
    <div className="flex flex-col rounded-md overflow-hidden">
      <div className="flex-1 bg-white border border-gray-200 p-6 flex flex-col justify-between">
        <Link to={`/news${slug}`} className="block">
          <h3 className="mt-2 text-xl leading-7 font-semibold text-black">{name}</h3>
          {metaDescription ? (
            <p className="my-3 text-base leading-6 text-gray-700">{metaDescription}</p>
          ) : (
            <></>
          )}
          <div className="mt-3">
            <p className="text-base leading-6 font-semibold text-black opacity-50 hover:opacity-100 transition ease-in-out duration-150">
              Read full story
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

ArticleCard.propTypes = {
  article: propTypes.shape({
    id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    slug: propTypes.string.isRequired,
    metaDescription: propTypes.string,
  }).isRequired,
};

export default ArticleCard;
