import { graphql } from 'gatsby';
import propTypes from 'prop-types';
import React, { useMemo } from 'react';

import PageTitle from '../components/PageTitle';
import ArticleCard from '../components/ArticleCard';
import ArticleGrid from '../components/ArticleGrid';
import Layout from '../layouts/Layout';
import { productFactory } from '../services/productFactory';

const Blog = ({ data }) => {
  const articles = useMemo(() => {
    return data.allMarkdownRemark.nodes.map(productFactory);
  }, [data]);

  return (
    <Layout title="Blog" metaDescription="Blog">
      <div className="w-full">
        <PageTitle title="Blog" />
        <ArticleGrid>
          {articles.map(article => (
            <ArticleCard key={article.id} article={article} />
          ))}
        </ArticleGrid>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(articles)/" } }) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          name
          metaDescription
          image {
            childImageSharp {
              fluid(maxWidth: 272, maxHeight: 363, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;

Blog.propTypes = {
  data: propTypes.shape({
    allMarkdownRemark: propTypes.shape({
      nodes: propTypes.array,
    }).isRequired,
  }).isRequired,
};

export default Blog;
