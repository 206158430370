import propTypes from 'prop-types';
import React from 'react';

const ArticleGrid = ({ children }) => {
  return <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">{children}</div>;
};

ArticleGrid.propTypes = {
  children: propTypes.arrayOf(propTypes.node),
};

ArticleGrid.defaultProps = {
  children: null,
};

export default ArticleGrid;
